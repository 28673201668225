<template>
  <div>
    <top-nav :showLogo="false" :title="$t('存储')"></top-nav>

    <div class="details-title">{{ $t('选择类型') }}</div>
    <div class="type-cont">
<!--      <div class="type-item" @click="handleSelected(levelPeriod['15'])">-->
<!--        15天-->
<!--        <span>-->
<!--          <img class="selected-icon" src="../../assets/image/bank/storage/selected-icon.png">-->
<!--        </span>-->
<!--      </div>-->
<!--      <div class="type-item" @click="handleSelected(levelPeriod['30'])">30天</div>-->
<!--      <div class="type-item" @click="handleSelected(levelPeriod['90'])">90天</div>-->
<!--      <div class="type-item" @click="handleSelected(levelPeriod['180'])">180天</div>-->
<!--      <div class="type-item" @click="handleSelected(levelPeriod['360'])">360天</div>-->
<!--      <div class="type-item" @click="handleSelected(levelPeriod['720'])">720天</div>-->

      <span v-for="(item, index) in days" :key="index">
        <div class="type-item" @click="handleSelected(levelPeriod[item], index)">{{ item }}天
          <span>
            <img v-if="showImg === index" class="selected-icon" src="../../assets/image/bank/storage/selected-icon.png">
          </span>
        </div>
      </span>
    </div>

    <div class="details-title">{{ $t('存储数量') }}</div>
    <div class="container">
      <div class="balance-cont">
        <div class="balance">
          {{ $t('钱包余额') }}:{{ balanceOf }} BUR
        </div>

        <div class="input-cont">
          <span style="float: left;padding: 0">BUR</span>
          <input type="text" v-model="amount" :placeholder="$t('请输入')" @focus="promptText =''"/>
          <span @click="maxAmount">MAX</span>
          <img v-if="amount" @click="emptyValue" class="empty-icon"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
      </div>
    </div>

    <div class="details-title">{{ $t('交易详情') }}</div>
    <div class="container" style="padding: 0.3rem 1.3rem">
      <div class="details-item">
        <div class="key">{{ $t('储蓄APY') }}</div>
        <div class="value"></div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('基础利息') }}</div>
        <div class="value">{{ (base_rate * 100).toFixed(2) }}%</div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('浮动利息') }}</div>
        <div class="value">{{ float_amount }}</div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('定期') }}</div>
        <div class="value">+ {{ interest }}</div>
      </div>
    </div>

    <div class="total-cont">
      <div class="key">{{ $t('存储本金') }}</div>
      <div class="value">{{ amount}} BUR</div>
    </div>
    <div class="total-cont">
      <div class="key">{{ $t('保险费用') }}</div>
      <div class="value">{{ insurance_costs }} BUR</div>
    </div>
    <div class="total-cont">
      <div class="key">{{ $t('总计') }}</div>
      <div class="value">{{ total_amount == 'NaN' ? 0 : total_amount }} BUR</div>
    </div>
    <div class="tips">
      {{ $t('根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%') }}
    </div>
    <div style="height: 5.5rem"></div>
    <div class="bottom_cont">
      <div class="_but" @click="depositCurrent">
        {{ $t('确定') }}
      </div>
    </div>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <div class="title">{{ $t('存入成功') }}</div>
          <div class="text">{{ amount }} BUR</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {keepDecimal, levelPeriod} from "@/utils/tools";

export default {
  name: "storagePeriodic",
  components: {TopNav},
  data() {
    return {
      account: null,
      amount: '',//存入数量
      balanceOf: '-',
      showOperationSuccess: false,
      promptText: '',
      swapBur: 0,
      rate: 0.005,
      total_amount: 0,
      days: [15,30,90,180,360,720],
      showImg: 0,
      insurance_costs: 0, //保险费用
      levelPeriod: {},
      base_rate: 0,
      extra_rate: 0,
      currentDeposit: 0, //活期总存款
      interest: 0, //利息
      float_amount: 0,
      day: 15
    }
  },
  watch: {
    'amount': function (newData) {

      this.interest = keepDecimal(this.computeFloatAmount(15))

      this.insurance_costs = keepDecimal((newData * this.extra_rate).toString()) //保险费用
      const temp_num = (parseFloat(newData * this.extra_rate) + parseFloat(this.amount))
      this.total_amount = keepDecimal(temp_num.toString()) // 总计

      console.log('days------', this.day)
      this.float_amount = keepDecimal((this.extra_rate * 0.0005*this.day/2.28 * 100 * this.amount).toString()) + '%'
      console.log('interest-----', this.interest)
    }
  },
  created() {
    this.init()
    this.levelPeriod = levelPeriod() // 定期时间对应的利息比例
    this.base_rate = this.levelPeriod[15]['rate']
    this.extra_rate = this.levelPeriod[15]['extra_rate']
    this.day = this.levelPeriod[15]['days']
  },
  methods: {
    async init() {
      this.amount = ''
      this.showImg = 0
      this.base_rate = 0.1
      await this.getAccount()
      await this.getAllowanceBurSwap()
      this.balanceOf = await this.$store.dispatch('bur/getBurBalance', this.account)
      this.currentDeposit = await this.$store.dispatch('bur/getCurrentDeposit', this.account) //活期存款
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
            }
          })
    },
    async getAllowanceBurSwap() {
      let that = this;
      this.$store.dispatch('bur/getAllowanceBurSwap', that.account)
          .then((res) => {
            console.log('getSwapBur2222222222222----', res)
            this.swapBur = res
          })

          .finally(() => {
            this.$toast.clear()
          })
    },
    async authorizedTransactionSwap(account, bur, amount) {
      let that = this;
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/authorizedTransactionSwapBur', {account: account, number: bur})
          .then((res) => {
            console.log('authorizedTransactionSwapBur----', res)
            if (res.status === true) {
              setTimeout(() => {
                that.toDepositFixed(account, amount)
              }, 300)
            }
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },

    toDepositFixed(account, bur) {
      let that = this
      that.$toast.loading({
        message: '',
        duration: 0,
      });

      this.$store.dispatch('bur/depositFixed',
          {"account": account, "amount": bur, index: this.showImg})
          .then(res => {
            console.log('toDepositFixed------', res);
            that.onShowActivationSuccess()
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },

    async depositCurrent() {
      let that = this;

      if (!that.amount) {
        that.$toast(that.$t("请输入正确的数量"));
        return;
      }

      if (that.currentDeposit < 100) {
        that.$toast(that.$t("活期存储不能小于100BUR"));
        return;
      }

      if (parseFloat(this.balanceOf) < parseFloat(this.total_amount)) {
        that.$toast(that.$t("总计不能大于钱包额度"));
        return;
      }

      if (this.swapBur < that.total_amount) {
        that.authorizedTransactionSwap(that.account, that.total_amount, that.amount)
      } else {
        that.toDepositFixed(that.account, that.amount)
        console.log('that.toDepositFixed(that.account, that.amount)', that.amount)
      }
    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.init()
      }
    },
    maxAmount() {
      this.amount = this.balanceOf
    },
    emptyValue() {
      this.amount = ''
      this.promptText = ''
    },
    handleSelected(params, index) {
      this.showImg = index;
      this.base_rate = params.rate
      this.extra_rate = params.extra_rate
      console.log('extra_rate----', this.extra_rate)
      this.day = params.days
      this.interest = this.computeFloatAmount(params.days)
      this.float_amount = keepDecimal((params.extra_rate * 0.0005*params.days/2.28 * 100 * this.amount).toString()) + '%'
      this.insurance_costs = keepDecimal((params.extra_rate * this.amount).toString()) //保险费用
      this.total_amount = parseFloat(this.insurance_costs) + parseFloat(this.amount)
    },
    computeFloatAmount(num) {
      return keepDecimal((this.levelPeriod[num]['rate'] * this.amount).toString())
    }
  }
}
</script>

<style scoped lang="less">
.container {
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.6rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;
}

.type-cont {
  margin: 0.7rem 4vw 0.7rem 4vw;

  .type-item {
    position: relative;
    width: 30%;
    margin: 0.3rem 1.65% 0.4rem;
    display: inline-block;
    height: 2.5rem;
    font-size: 1rem;
    color: #B5904E;
    line-height: 2.5rem;
    text-align: center;
    background: #1B1B1B;
    border-radius: 0.71rem;
  }

  .selected-icon {
    position: absolute;
    width: 2.5rem;
    bottom: 0;
    right: 0;
  }
}

.balance-cont {
  padding: 1rem;
  background: #101010;
  border-radius: 0.71rem;

  .balance {
    font-size: 0.79rem;
    color: #6E6045;
  }

  .input-cont {
    text-align: right;
    padding-top: 1.5rem;

    input {
      width: 50%;
      text-align: right;
      font-size: 1rem;
      color: #fff;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }

    span {
      padding-left: 1.5rem;
      font-size: 0.79rem;
      color: #6E6045;
    }

    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }
}

.details-title {
  padding-top: 1rem;
  margin: 0 2.5rem;
  font-size: 1.07rem;
  color: #E7BD71;
}

.details-item {
  overflow: hidden;
  padding: 1.3rem 0;
  border-bottom: 1px solid #262526;

  .key {
    float: left;
    font-size: 0.93rem;
    color: #6E6045;
  }

  .value {
    float: right;
    font-size: 1.07rem;
    color: #B5904E;
  }
}

.details-item:last-child {
  border-bottom: none;
}

.total-cont {
  overflow: hidden;
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.1rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;


  .key {
    float: left;
    font-size: 1.07rem;
    color: #B5904E;
  }

  .value {
    float: right;
    font-size: 1.07rem;
    font-weight: bold;
    color: #B5904E;
  }
}

.tips {
  text-align: center;
  font-size: 0.86rem;
  color: #625F5F;
}

.bottom_cont {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 5rem;
  background: #151515;
  padding: 1.1rem 0.9rem;
  box-sizing: border-box;

  ._but {
    height: 2.86rem;
    line-height: 2.86rem;
    color: #000000;
    font-size: 1.14rem;
    text-align: center;
    background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.43rem;
  }
}

</style>
